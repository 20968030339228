import { useCustomerStore } from '~/stores/customer.store'

export default defineNuxtRouteMiddleware(async (to: any, from: any) => {
  const nuxtApp = useNuxtApp()
  const customerStore = useCustomerStore(nuxtApp.$pinia)

  const { role, token } = to.query
  const hasToken = !!token

  customerStore.setRole(role)
  customerStore.setPublicMode(!hasToken)
})
